<template>
  <div class="container">
    <div class="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
      <div class="max-w-md mx-auto my-10 bg-white p-5 rounded-md shadow-sm">
        <div class="text-center">
          <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Personal Information</h1>
        </div>
        <div class="m-7">
          <form action="" method="" id="">
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">NRIC/FIN</label>
              <input type="text" name="nric" id="nric" v-model="detail.uinfin.value" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Full Name</label>
              <input type="text" name="name" id="name" v-model="detail.name.value" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="email" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
              <input type="email" name="email" id="email" v-model="detail.email.value" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="phone" class="text-sm text-gray-600 dark:text-gray-400">Phone Number</label>
              <input type="text" name="phone" id="phone" v-model="detail.phone" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Gender</label>
              <input type="text" name="gender" id="gender" v-model="detail.sex.desc" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Date of Birth</label>
              <input type="text" name="dob" id="dob" v-model="detail.dob.value" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Occupation</label>
              <input type="text" name="occ" id="occ" v-model="detail.occupation.desc" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Resident Status</label>
              <input type="text" name="res" id="res" v-model="detail.residentialstatus.desc" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Address - Block</label>
              <input type="text" name="add_block" id="add_block" v-model="detail.regadd.block.value" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Address - Building</label>
              <input type="text" name="bldg" id="bldg" v-model="detail.regadd.building.value" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Address - Unit/Floor</label>
              <input type="text" name="unit_floor" id="unit_floor" v-model="detail.unit_floor" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Address - Street</label>
              <input type="text" name="street" id="street" v-model="detail.regadd.street.value" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Address - Country</label>
              <input type="text" name="country" id="country" v-model="detail.regadd.country.desc" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Address - Postal Code</label>
              <input type="text" name="postal" id="postal" v-model="detail.regadd.postal.value" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
            </div>
            <div class="mb-6">
              <button type="submit" class="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none">Send Message</button>
            </div>
            <p class="text-base text-center text-gray-400" id="result"></p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/NDIMyInfoApi'
export default {
  name: "Callback",
  data: () => {
    return {
      ret: {},
      detail: {},
      code: "",
      state: "",
    }
  },
  async created () {
    this.code = this.$route.query.code;
    this.state = this.$route.query.state;
    var data = {
      code: this.code,
      state: this.state,
    };
    this.ret = await api.createTokenRequest(JSON.stringify(data));
    if(this.ret) {
      this.loadData(JSON.parse(this.ret.data));
    }
  },
  methods: {
    async loadData (data) {
      console.log(data);
      this.detail = data;
      this.detail.phone = (this.detail.mobileno.prefix.value + this.detail.mobileno.areacode.value + ' ' + this.detail.mobileno.nbr.value);
      this.detail.unit_floor = ('#' + this.detail.regadd.floor.value + '-' + this.detail.regadd.unit.value);
    },    
  },
};
</script>